@tailwind base;
@tailwind components;
@tailwind utilities;


/* fonts */

*{
  scroll-behavior: smooth;
}

@import url('https://fonts.googleapis.com/css2?family=Alexandria:wght@100;200;300;400;500;600;700;800;900&family=Cairo:wght@200;300;400;500;600;700;800;900;1000&family=Poppins:ital,wght@0,300;0,400;1,300&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

select option {
  background-color: rgb(105, 104, 104)!important;
  color: #fff !important;
}
body{
  scroll-behavior: smooth;
  font-family: 'Cairo', sans-serif !important;
  font-family: 'Poppins', sans-serif;
  font-family: 'Roboto', sans-serif;
  }
::-webkit-scrollbar {
  width: 5px;
  height: 5px;

}

/* Track */
::-webkit-scrollbar-track {

      width: 5px;
      height: 5px;


  }
 
/* Handle */
::-webkit-scrollbar-thumb {
 
    width: 5px;
    height: 5px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {

    width: 5px;
    height: 5px;


   }
input,select{
  outline: none;
}
.batch_heart{
  background: tomato;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  right: -24%;
  top: -9%;
  cursor: pointer;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
:root {
  --black-gradient: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

* {
  scroll-behavior: smooth;
}
.text-gradient {  
color: #4BAD81;
font-family: 'Cairo', sans-serif !important;

}
h1,h2,h3,h4,h5,h6 p {
  font-family: 'Cairo', sans-serif !important;
}
.css-mbql76-MuiTypography-root{
  font-family: 'Cairo', sans-serif !important;
}
.css-1p189nd-MuiTypography-root-MuiLink-root{
  font-family: 'Cairo', sans-serif !important;
}
.css-psym5z-MuiTypography-root-MuiLink-root{
  font-family: 'Cairo', sans-serif !important;
}
.css-1swivyk-MuiInputBase-input-MuiOutlinedInput-input{
  font-family: 'Cairo', sans-serif !important;
}
.MuiButtonBase-root{
  font-family: 'Cairo', sans-serif !important;

}
.css-9ow9g-MuiTypography-root{
  font-family: 'Cairo', sans-serif !important;
}

.css-xkwsl7-MuiTypography-root{
  font-family: 'Cairo', sans-serif !important;
}
.MuiTypography-root{
  font-family: 'Cairo', sans-serif !important;
  
}
.bg-blue-gradient {
  background: linear-gradient(
    157.81deg,
    #def9fa -43.27%,
    #4BAD81 -21.24%,
    #4BAD81 12.19%,
    #4BAD81 29.82%,
    #4BAD81 51.94%,
    #4BAD81 90.29%
  );
}


.bg-black-gradient {
  background: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}
.bg-black-gradient {
  background: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-gray-gradient {
  background: linear-gradient(
    153.47deg,
    rgba(255, 255, 255, 0) -341.94%,
    #14101d 95.11%
  );
}

.bg-discount-gradient {
  background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
}

.box-shadow {
  box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

.sidebar {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.feature-card:hover {
  background: var(--black-gradient);
  box-shadow: var(--card-shadow);
}

.feedback-container .feedback-card:last-child {
  margin-right: 0px;
}
.feedback-card {
  background: transparent;
}

.feedback-card{
  background: var(--black-gradient);
}
.blue__gradient {
  background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
  filter: blur(123px);
}
.navbar.hidden{
  display: none;
}

.loaderJs{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#wifi-loader {
  --background: #62abff;
  --front-color: #4f29f0;
  --back-color: #c3c8de;
  --text-color: #414856;
  width: 64px;
  height: 64px;
  border-radius: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

#wifi-loader svg {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

#wifi-loader svg circle {
  position: absolute;
  fill: none;
  stroke-width: 6px;
  stroke-linecap: round;
  stroke-linejoin: round;
  transform: rotate(-100deg);
  transform-origin: center;
}

#wifi-loader svg circle.back {
  stroke: var(--back-color);
}

#wifi-loader svg circle.front {
  stroke: var(--front-color);
}

#wifi-loader svg.circle-outer {
  height: 86px;
  width: 86px;
}

#wifi-loader svg.circle-outer circle {
  stroke-dasharray: 62.75 188.25;
}

#wifi-loader svg.circle-outer circle.back {
  animation: circle-outer135 1.8s ease infinite 0.3s;
}

#wifi-loader svg.circle-outer circle.front {
  animation: circle-outer135 1.8s ease infinite 0.15s;
}

#wifi-loader svg.circle-middle {
  height: 60px;
  width: 60px;
}

#wifi-loader svg.circle-middle circle {
  stroke-dasharray: 42.5 127.5;
}

#wifi-loader svg.circle-middle circle.back {
  animation: circle-middle6123 1.8s ease infinite 0.25s;
}

#wifi-loader svg.circle-middle circle.front {
  animation: circle-middle6123 1.8s ease infinite 0.1s;
}

#wifi-loader svg.circle-inner {
  height: 34px;
  width: 34px;
}

#wifi-loader svg.circle-inner circle {
  stroke-dasharray: 22 66;
}

#wifi-loader svg.circle-inner circle.back {
  animation: circle-inner162 1.8s ease infinite 0.2s;
}

#wifi-loader svg.circle-inner circle.front {
  animation: circle-inner162 1.8s ease infinite 0.05s;
}

#wifi-loader .text {
  position: absolute;
  bottom: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: lowercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.2px;
}

#wifi-loader .text::before, #wifi-loader .text::after {
  content: attr(data-text);
}

#wifi-loader .text::before {
  color: var(--text-color);
}

#wifi-loader .text::after {
  color: var(--front-color);
  animation: text-animation76 3.6s ease infinite;
  position: absolute;
  left: 0;
}

@keyframes circle-outer135 {
  0% {
    stroke-dashoffset: 25;
  }

  25% {
    stroke-dashoffset: 0;
  }

  65% {
    stroke-dashoffset: 301;
  }

  80% {
    stroke-dashoffset: 276;
  }

  100% {
    stroke-dashoffset: 276;
  }
}

@keyframes circle-middle6123 {
  0% {
    stroke-dashoffset: 17;
  }

  25% {
    stroke-dashoffset: 0;
  }

  65% {
    stroke-dashoffset: 204;
  }

  80% {
    stroke-dashoffset: 187;
  }

  100% {
    stroke-dashoffset: 187;
  }
}

@keyframes circle-inner162 {
  0% {
    stroke-dashoffset: 9;
  }

  25% {
    stroke-dashoffset: 0;
  }

  65% {
    stroke-dashoffset: 106;
  }

  80% {
    stroke-dashoffset: 97;
  }

  100% {
    stroke-dashoffset: 97;
  }
}

@keyframes text-animation76 {
  0% {
    clip-path: inset(0 100% 0 0);
  }

  50% {
    clip-path: inset(0);
  }

  100% {
    clip-path: inset(0 0 0 100%);
  }
}
 
.scanner span {
    color: transparent;
    font-size: 1.4rem;
    position: relative;
    overflow: hidden;
  }
  
  .scanner span::before {
    content: "Loading...";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    border-right: 4px solid #17FEFF;
    overflow: hidden;
    color: #17FEFF;
    animation: load91371 2s linear infinite;
  }
  
  @keyframes load91371 {
    0%, 10%, 100% {
      width: 0;
    }
  
    10%,20%,30%,40%,50%,60%,70%,80%,90%,100% {
      border-right-color: transparent;
    }
  
    11%,21%,31%,41%,51%,61%,71%,81%,91% {
      border-right-color: #17FEFF;
    }
  
    60%, 80% {
      width: 100%;
    }
  }

  .checkbox {
    width: 150px;
    height: 40px;
    background-color: #d0d0d0;
    border-radius: 30px;
    position: relative;
    color: black;
    overflow: hidden;
  }
  
  #checkbox_toggle {
    display: none;
  }
  
  .checkbox .toggle {
    width: 60px;
    height: 30px;
    position: absolute;
    border-radius: 30px;
    left: 10px;
    cursor: pointer;
    background: linear-gradient(40deg, #FF0080,#FF8C00 70%);
    transition: 0.4s;
    box-shadow: 0px 0px 10px rgb(255, 255, 20), 0px 0px 30px rgb(255, 255, 20);
  }
  
  .checkbox .slide {
    width: 160px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
  }
  
  .checkbox .slide .text {
    font-size: 16px;
    font-weight: 500;
    z-index: 100;
    cursor: pointer;
  }
  
  .check:checked + .checkbox .slide .toggle {
    transform: translateX(75px);
    background: linear-gradient(40deg, #8983F7, #A3DAFB 70%);
    box-shadow: -0px -0px 10px #8983F7, -0px -0px 30px #8983F7;
  }
  
  .check:checked + .checkbox .slide {
    background-color: #0a1929;
    color: #fff;
  }
  .switch {
    position: relative;
    width: 130px;
    height: 50px;
    margin: 0px;
    appearance: none;
    -webkit-appearance: none;
    background-color: rgb(4,52,73);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 25px;
    transition: background-image .7s ease-in-out;
    outline: none;
    cursor: pointer;
    overflow: hidden;
  }
  
  .switch:checked {
    background-color: rgb(0, 195, 255);
    background-size: cover;
    transition: background-image 1s ease-in-out;
  }
  
  .switch:after {
    content: '';
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    left: 2px;
    top: 2px;
    transform: translateX(0px);
    animation: off .7s forwards cubic-bezier(.8, .5, .2, 1.4);
    box-shadow: inset 5px -5px 4px rgba(53, 53, 53, 0.3);
  }
  
  @keyframes off {
    0% {
      transform: translateX(80px);
      width: 46px;
    }
  
    50% {
      width: 75px;
      border-radius: 25px;
    }
  
    100% {
      transform: translateX(0px);
      width: 46px;
    }
  }
  
  .switch:checked:after {
    animation: on .7s forwards cubic-bezier(.8, .5, .2, 1.4);
    box-shadow: inset -5px -5px 4px rgba(53, 53, 53, 0.3);
  }
  
  @keyframes on {
    0% {
      transform: translateX(0px);
      width: 46px;
    }
  
    50% {
      width: 75px;
      border-radius: 25px;
    }
  
    100% {
      transform: translateX(80px);
      width: 46px;
    }
  }
  
  .switch:checked:before {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    left: 15px;
    top: 5px;
    transform-origin: 53px 10px;
    background-color: transparent;
    box-shadow: 5px -1px 0px #fff;
    filter: blur(0px);
    animation: sun .7s forwards ease;
  }
  
  @keyframes sun {
    0% {
      transform: rotate(170deg);
      background-color: transparent;
      box-shadow: 5px -1px 0px #fff;
      filter: blur(0px);
    }
  
    50% {
      background-color: transparent;
      box-shadow: 5px -1px 0px #fff;
      filter: blur(0px);
    }
  
    90% {
      background-color: #f5daaa;
      box-shadow: 0px 0px 10px #f5deb4,
      0px 0px 20px #f5deb4,
      0px 0px 30px #f5deb4,
       inset 0px 0px 2px #efd3a3;
      filter: blur(1px);
    }
  
    100% {
      transform: rotate(0deg);
      background-color: #f5daaa;
      box-shadow: 0px 0px 10px #f5deb4,
      0px 0px 20px #f5deb4,
      0px 0px 30px #f5deb4,
       inset 0px 0px 2px #efd3a3;
      filter: blur(1px);
    }
  }
  .MuiTableCell-root {
    text-align: center !important;

  }

  
  .MuiTableCell-head {
    text-align: center !important;
  } 
  
  .switch:before {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    left: 15px;
    top: 5px;
    filter: blur(1px);
    background-color: #f5daaa;
    box-shadow: 0px 0px 10px #f5deb4,
  0px 0px 20px #f5deb4,
  0px 0px 30px #f5deb4,
   inset 0px 0px 2px #efd3a3;
    transform-origin: 53px 10px;
    animation: moon .7s forwards ease;
  }
  
  @keyframes moon {
    0% {
      transform: rotate(0deg);
      filter: blur(1px);
    }
  
    50% {
      filter: blur(1px);
    }
  
    90% {
      background-color: transparent;
      box-shadow: 5px -1px 0px #fff;
      filter: blur(0px);
    }
  
    100% {
      transform: rotate(170deg);
      background-color: transparent;
      box-shadow: 5px -1px 0px #fff;
      filter: blur(0px);
    }
  }
  .datatab button {
    position: relative;
    width: 11em;
    height: 4em;
    outline: none;
    transition: 0.1s;
    background-color: transparent;
    border: none;
    font-size: 13px;
    font-weight: bold;
    color: #ddebf0;
  }
  
  #clip {
    --color: #2761c3;
    position: absolute;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border: 5px double var(--color);
    box-shadow: inset 0px 0px 15px #195480;
    -webkit-clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  }
  
  .arrow {
    position: absolute;
    transition: 0.2s;
    background-color: #2761c3;
    top: 35%;
    width: 11%;
    height: 30%;
  }
  
  #leftArrow {
    left: -13.5%;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 50%);
  }
  
  #rightArrow {
    -webkit-clip-path: polygon(100% 49%, 0 0, 0 100%);
    left: 102%;
  }
  
  .datatab button:hover #rightArrow {
    background-color: #27c39f;
    left: -15%;
    animation: 0.6s ease-in-out both infinite alternate rightArrow8;
  }
  
  .datatab button:hover #leftArrow {
    background-color: #27c39f;
    left: 103%;
    animation: 0.6s ease-in-out both infinite alternate leftArrow8;
  }
  
  .corner {
    position: absolute;
    width: 4em;
    height: 4em;
    background-color: #2761c3;
    box-shadow: inset 1px 1px 8px #2781c3;
    transform: scale(1) rotate(45deg);
    transition: 0.2s;
  }
  
  #rightTop {
    top: -1.98em;
    left: 91%;
  }
  
  #leftTop {
    top: -1.96em;
    left: -3.0em;
  }
  
  #leftBottom {
    top: 2.10em;
    left: -2.15em;
  }
  
  #rightBottom {
    top: 45%;
    left: 88%;
  }
  
  .datatab button:hover #leftTop {
    animation: 0.1s ease-in-out 0.05s both changeColor8,
    0.2s linear 0.4s both lightEffect8;
  }
  
  .datatab button:hover #rightTop {
    animation: 0.1s ease-in-out 0.15s both changeColor8,
    0.2s linear 0.4s both lightEffect8;
  }
  
  .datatab button:hover #rightBottom {
    animation: 0.1s ease-in-out 0.25s both changeColor8,
    0.2s linear 0.4s both lightEffect8;
  }
  
  .datatab button:hover #leftBottom {
    animation: 0.1s ease-in-out 0.35s both changeColor8,
    0.2s linear 0.4s both lightEffect8;
  }
  
  .datatab button:hover .corner {
    transform: scale(1.25) rotate(45deg);
  }
  
  .datatab button:hover #clip {
    animation: 0.2s ease-in-out 0.55s both greenLight8;
    --color: #27c39f;
  }
  
  @keyframes changeColor8 {
    from {
      background-color: #2781c3;
    }
  
    to {
      background-color: #27c39f;
    }
  }
  
  @keyframes lightEffect8 {
    from {
      box-shadow: 1px 1px 5px #27c39f;
    }
  
    to {
      box-shadow: 0 0 2px #27c39f;
    }
  }
  
  @keyframes greenLight8 {
    from {
    }
  
    to {
      box-shadow: inset 0px 0px 32px #27c39f;
    }
  }
  
  @keyframes leftArrow8 {
    from {
      transform: translate(0px);
    }
  
    to {
      transform: translateX(10px);
    }
  }
  
  @keyframes rightArrow8 {
    from {
      transform: translate(0px);
    }
  
    to {
      transform: translateX(-10px);
    }
  }
  
  
  